/**
 * core状态管理
 */
export default {
    namespaced: true,
    state: {
        status:'', //状态
    },
    mutations: {
        /**
         * 状态
         * @param state
         * @param status
         * @constructor
         */
        SET_STATUS (state,status) {
            state.status = status;
        },
    },
}
