import Vue from 'vue'
import Vuex from 'vuex'
import service from './service'
import core from './core'
import header from './header'

Vue.use(Vuex)


export default new Vuex.Store({
  modules:{
    service,
    core,
    header
  }
})
