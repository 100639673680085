/**
 * service状态管理
 */
export default {
    namespaced: true,
    state: {
        status:0, //状态
    },
    mutations: {
        /**
         * 状态
         * @param state
         * @param status
         * @constructor
         */
        SET_SERVICE_STATUS (state,status) {
            state.status = status;
        },
    },
}
