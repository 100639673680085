import Vue from "vue";
import VueRouter from "vue-router";
const Index = () =>  import('@/views/Index/index.vue');
const Home = () =>  import('@/views/Home/home.vue');
const ServiceContent = () =>  import('@/views/Service/Content/content.vue');
const CoreContent = () =>  import('@/views/Core/Content/content.vue');
const CaseContent = () =>  import('@/views/Case/Content/content.vue');

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Index",
        component: Index,
        children:[
            {
                path:'/',
                name:'Home',
                component: Home,
            },
            {
                path:'/service/content',
                name:'content',
                component: ServiceContent,
            },
            {
                path:'/core/content',
                name:'coreContent',
                component: CoreContent,
            },
            {
                path:'/case/content',
                name:'caseContent',
                component: CaseContent,
            },
        ]
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});
router.beforeEach((to, from, next) => {
    document.title = '北京昊明数科营销顾问有限公司'
    if (to.matched.length === 0) { //匹配前往的路由不存在
        from.name ? next({
            name: from.name
        }) : next('/'); //判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到首页
    } else {
        next()
    }
});
export default router;
