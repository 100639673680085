/**
 * header状态管理
 */
export default {
    namespaced: true,
    state: {
        point:'', //锚点
    },
    mutations: {
        /**
         * 锚点
         * @param state
         * @param status
         * @constructor
         */
        SET_POINT (state,point) {
            state.point = point;
        },
    },
}
