<template>
    <div id="app">
        <router-view/>
    </div>
    <!--background: #071b2c;-->
</template>

<style lang="less">
html,body,#app {height: 100%; margin: 0; padding: 0; background: #071b2c; font-size: 14px;font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,"\5FAE\8F6F\96C5\9ED1",Arial,sans-serif;}
</style>
<script>
export default {
  name: 'App',
  created () {
    //在页面加载时读取localStorage里的状态信息
    if (localStorage.getItem("store") ) {
      this.$store.replaceState(Object.assign({}, this.$store.state,JSON.parse(localStorage.getItem("store"))))
    }

    //在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener("beforeunload",()=> {
      localStorage.setItem("store",JSON.stringify(this.$store.state))
    })
  },
}
</script>
